import { PROJECT_SCHEDULE } from './fragments';

import { API } from 'aws-amplify';

export default {
  createProjectColumn(variables) {
    const query = `
      mutation createProjectColumn (
        $columnName: String!
        $projectId: String!
        $scheduleId: String!
      ) {
        response: createProjectColumn (
          columnName: $columnName
          projectId: $projectId
          scheduleId: $scheduleId
        ) {
          ${PROJECT_SCHEDULE}
        }
      }
    `;
    return API.graphql({
      query,
      variables,
    });
  },
  deleteProjectCustomColumn(variables) {
    const query = `
      mutation deleteProjectCustomColumn($columnName: String!, $projectId: String!, $scheduleId: String!) {
        response: deleteProjectCustomColumn(columnName: $columnName, projectId: $projectId, scheduleId: $scheduleId) {
          ${PROJECT_SCHEDULE}
        }
      }
    `;
    return API.graphql({
      query,
      variables,
    });
  },
  renameProjectCustomColumn(variables) {
    const query = `
      mutation renameProjectCustomColumn($columnName: String!, $newColumnName: String!, $projectId: String!, $scheduleId: String!) {
        response: renameProjectCustomColumn(columnName: $columnName, newColumnName: $newColumnName, projectId: $projectId, scheduleId: $scheduleId) {
          ${PROJECT_SCHEDULE}
        }
      }
    `;
    return API.graphql({
      query,
      variables,
    });
  },
};
